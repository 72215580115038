import TableAction from '@/components/TableAction.vue';
import Pagination from '@/components/Pagination.vue';
// import Button from '@/components/button.vue';
// import Avatar from '@/components/avatar.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import SnackbarAlert from '@/components/SnackbarAlert.vue';
//import VideoPlayer from '@/components/VideoPlayer.vue';
//import FileUpload from '@/components/FileUpload.vue';
import TitlePage from '@/components/TitlePage.vue';

export default {
    install: (app) => {
        app.component("t-table-action", TableAction);
        app.component("t-pagination", Pagination);
        app.component("t-confirm", ConfirmDialog);
        app.component("t-snackbar", SnackbarAlert);
        app.component("t-title-page", TitlePage);
        //app.component("t-player", VideoPlayer);
        //app.component("t-fileupload", FileUpload);
        // app.component("e-button", Button);
        // app.component("e-avatar", Avatar);
    },
  };
  