<template>
    <v-container class="title-page">
        <v-row justify="center">
            <v-col cols="12" class="text-h5 pl-0">
                {{ uppercaseTitle }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: "My Title Page",
        },
    },
    computed: {
        uppercaseTitle() {
            if (!this.title) return "";
            return this.title
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        },
    },
};
</script>

<style scoped>
</style>