<template>
    <div>
        <v-row class="mx-0">
            <slot name="searchBox">
                <template
                    v-if="
                        onPage == 'attendance_employee' ||
                        onPage == 'career' ||
                        onPage == 'project_member' ||
                        onPage == 'location_tracking_employee' ||
                        onPage == 'project_member' ||
                        onPage == 'Group Shift Schedule'
                    "
                >
                    <span></span>
                </template>
                <template v-else>
                    <v-text-field
                        v-model="searchText"
                        class="mr-1 mt-3 w-100"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        @keyup="onSearchKeyUp"
                        @click:append="onSearchEnter()"
                        :placeholder="searchPlaceholder"
                        clearable
                        @click:clear="onClearSearch"
                        hide-details
                    ></v-text-field>
                </template>
            </slot>
            <v-spacer></v-spacer>
            <slot name="newButton">
                <v-btn
                    v-if="onNewBtn"
                    color="primary"
                    class="text-none mr-1 mt-3"
                    dense
                    @click="onNewBtn"
                    elevation="0"
                    ><v-icon small class="mr-2">mdi-plus</v-icon
                    ><template v-if="!textOnNewBtn"> New</template
                    ><template v-else>Create Schedule</template></v-btn
                >
            </slot>
            <slot name="deleteButton">
                <v-btn
                    v-if="onDeleteBtn || onDeleteBtnShow"
                    color="error"
                    class="text-none mr-1 mt-3"
                    dense
                    @click="onDeleteClick"
                    elevation="0"
                    ><v-icon small class="mr-2">mdi-delete-outline</v-icon>
                    Delete</v-btn
                >
            </slot>
            <slot name="refreshButton">
                <v-btn
                    v-if="onRefreshBtn"
                    color="secondary"
                    class="text-none mr-1 mt-3"
                    dense
                    @click="onRefreshBtn"
                    elevation="0"
                    ><v-icon small class="mr-2">mdi-reload</v-icon>
                    Refresh</v-btn
                >
            </slot>
            <slot name="exportButton" v-if="onExportBtn">
                <download-excel :fetch="fetchData" :fields="jsonFields">
                    <v-btn
                        color="green"
                        class="text-none mr-1 mt-3 text-white-custom"
                        elevation="0"
                        ><v-icon small class="mr-2">mdi-file-excel</v-icon>
                        Export</v-btn
                    >
                </download-excel>
            </slot>
            <slot name="filterButton" v-if="onFilterBtn">
                <v-btn
                    class="text-none mr-1 mt-3"
                    color="orange darken-1"
                    @click="expand = !expand"
                    elevation="0"
                >
                    <v-icon small class="mr-2">mdi-filter</v-icon> Filter
                </v-btn>
            </slot>
        </v-row>
        <v-row class="mx-0">
            <v-col class="px-0">
                <FilterAction
                    ref="filterAction"
                    :expand="expand"
                    @data-check-out-sent="$emit('data-check-out-sent', $event)"
                    @date-period-changed="onDatePeriodChanged"
                    :onPageNested="onPage"
                    @data-filter="handleFilter"
                />
            </v-col>
        </v-row>
        <ConfirmDialog ref="confirmDialog" />
    </div>
</template>

<script>
import { debounce } from "lodash";
export default {
    name: "TableAction",
    props: {
        onNewBtn: Function,
        onDeleteBtn: Function,
        onRefreshBtn: Function,
        searchValue: String,
        onDeleteBtnShow: Boolean,
        onSearch: {},
        onExportBtn: {},
        onFilterBtn: {},
        selectedIds: {},
        jsonFields: {},
        onPage: {},
        textOnNewBtn: {},
        searchPlaceholder: null,
    },
    components: {
        FilterAction: () => import("@/components/FilterAction"),
        ConfirmDialog: () => import("@/components/ConfirmDialog"),
    },
    data() {
        return {
            searchText: "",
            expand: false,
        };
    },
    methods: {
        // Filter Function
        handleFilter(data) {
            this.filterData = data;
            this.$emit("forward-filter", this.filterData);
        },

        // Search Function
        onSearchEnter() {
            this.$emit("onSearchEnter", this.searchText);
        },
        onSearchKeyUp: debounce(function () {
            // Debounce akan menunda eksekusi onSearchEnter setelah pengguna berhenti mengetik selama 300ms
            this.onSearchEnter();
        }, 300),
        onClearSearch() {
            this.$emit("onSearchEnter", (this.searchText = ""));
        },

        // Delete Function
        onDeleteClick() {
            // if (!this.selectedIds.length) {
            //     this.dialogEmptyExport();
            // } else {
                // Lanjutkan ke fungsi delete jika ada data yang di-select
                this.onDeleteBtn();
            // }
        },

        async dialogEmptyExport() {
            await this.$refs.confirmDialog.open(
                "Information",
                "Please select the data first",
                { noconfirm: true }
            );
        },

        async fetchData() {
            if (this.selectedIds.length) {
                return this.selectedIds;
            } else {
                this.dialogEmptyExport();
            }
        },

        onDatePeriodChanged(data) {
            this.$emit("date-period-changed", data);
        },
    },
    mounted() {
        this.searchText = this.searchValue == null ? "" : this.searchValue;
    },
    computed: {},
    watch: {},
};
</script>

<style scoped>
.text-white-custom {
    color: #ffffff !important;
}
</style>


