import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import moment from 'moment';
import numeral from 'numeral';
import JsonExcel from "vue-json-excel";
import './assets/styles.css';
//import { loadScript } from 'esri-loader';
import componentPlugin from './plugins/component';


Vue.component("downloadExcel", JsonExcel);

Vue.use(componentPlugin);

Vue.config.productionTip = false

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD MMM, YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('lll')
  }
})

Vue.filter('formatDateTimeTimeZone', function(value) {
  if (value) {
    // Konversi input date ke Date object
    let date = new Date(value);

    // Buat opsi format untuk menampilkan tanggal
    let options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Asia/Jakarta',  // Mengatur zona waktu WIB
        timeZoneName: 'short'
    };

    // Format date menggunakan toLocaleString
    let formattedDate = date.toLocaleString('en-US', options).replace('GMT+7', 'WIB');

    // Hilangkan koma setelah tahun
    formattedDate = formattedDate.replace(',', '');

    return formattedDate;
  }
})

Vue.filter('formatDateTimeZone', function(value) {
    if (value) {
        const date = new Date(value);

        // Kurangi 7 jam dari waktu UTC
        date.setUTCHours(date.getUTCHours() - 12);

        // Array untuk nama bulan singkatan
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Ambil komponen-komponen tanggal
        const month = monthNames[date.getUTCMonth()];
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();

        // Ambil komponen-komponen waktu
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';

        // Format jam dalam format 12 jam
        hours = hours % 12;
        hours = hours ? hours : 12; // Jam 0 menjadi 12
        minutes = minutes < 10 ? '0' + minutes : minutes; // Tambah '0' jika menit < 10

        // Susun dalam format yang diinginkan
        const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes} ${ampm} WIB`;

        return formattedDate;
    }
})

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('LTS')
  }
})

Vue.filter('formatNumber', function(value) {
  if (value) {
    return numeral(value).format("0,0");
  }else{
    return "0";
  }
})


Vue.filter('getDayName', function(value) {
  let days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return days[value];
})

Vue.filter('getDays', function() {
    return [
      {id:0, label:"Monday"},
      {id:1, label:"Tuesday"},
      {id:2, label:"Wednesday"},
      {id:3, label:"Thursday"},
      {id:4, label:"Friday"},
      {id:5, label:"Saturday"},
      {id:6, label:"Sunday"},
    ];
})

Vue.filter('getWorkTimes', function() {
  return [
    {id:1, label:"01:00"},
    {id:2, label:"02:00"},
    {id:3, label:"03:00"},
    {id:4, label:"04:00"},
    {id:5, label:"05:00"},
    {id:6, label:"06:00"},
    {id:7, label:"07:00"},
    {id:8, label:"08:00"},
    {id:9, label:"09:00"},
    {id:10, label:"10.00"},
    {id:11, label:"11.00"},
    {id:12, label:"12.00"},
    {id:13, label:"13.00"},
    {id:14, label:"14.00"},
    {id:15, label:"15.00"},
    {id:16, label:"16.00"},
    {id:17, label:"17.00"},
    {id:18, label:"18.00"},
    {id:19, label:"19.00"},
    {id:20, label:"20.00"},
    {id:21, label:"21.00"},
    {id:22, label:"22.00"},
    {id:23, label:"23.00"},
    {id:24, label:"00.00"},
  ];
})


Vue.filter('test', function() {
  return 'Tost';
})

// const options = {
//   url: 'https://js.arcgis.com/3.23/',
// };
// loadScript(options)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
