<template>
    <div>
      <v-snackbar
            v-model="show"
            :timeout="timeout"
          >
        {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </div>
</template>

<script>
export default {
    name:"SnackbarAlert",
    data: () => ({
      show: false,
      text: '',
      timeout: 2000,
    }),

  methods: {
    open(message, timeout) {
      this.show = true;
      this.text = message;
      this.timeout = timeout == 0 ? 1500 : timeout;
    },
  },
}
</script>

<style>

</style>