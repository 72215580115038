<template>
    <v-toolbar flat class="mt-3">
        <span class="text-nowrap mr-3"
            >Showing {{ page * limit - limit + 1 }} to
            {{ page * limit >= total ? total : page * limit }} of
            {{ total }} rows</span
        >
        <span class="text-nowrap mr-3"></span>
        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    outlined
                    class="text-none"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    >{{ limit }}</v-btn
                >
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in [10, 15, 30, 100, 200, 500]"
                    :key="index"
                    @click="onItemPerPageChange(item)"
                >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-pagination
            v-if="Math.floor(total / limit) + (total % limit > 0 ? 1 : 0) > 1"
            class="tablePagination"
            :value="page"
            :length="Math.floor(total / limit) + (total % limit > 0 ? 1 : 0)"
            :total-visible="6"
            circle
            @input="onPageChange"
        ></v-pagination>
    </v-toolbar>
</template>

<script>
export default {
    name: "PaginationComponent",
    props: {
        total: Number,
        limit: Number,
        page: Number,
        //onPageChange: Function,
        //onItemPerPageChange: Function,
    },
    components: {},
    data() {
        return {};
    },
    methods: {
        onItemPerPageChange(value){
            this.$emit("onItemPerPageChange", value);
        },
        onPageChange(value){
            this.$emit("onPageChange", value);
        }
    },
    mounted() {},
    computed: {},
    watch: {},
};
</script>

<style>
</style>


