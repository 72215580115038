import Vue from 'vue'
import Router from "vue-router";
import cookie from 'vue-cookie';

Vue.use(Router);

let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/login",
            name: "Login",
            component: () => import('@/screens/auth/LoginScreen.vue'),
            meta: {
                guest: true
            }
        },
        {
            path: "/register",
            name: "Register",
            component: () => import('@/screens/auth/RegisterScreen.vue'),
            meta: {
                guest: true
            }
        },
        {
            path: "/registerverificationcode",
            name: "RegisterVerificationCode",
            component: () => import('@/screens/auth/RegisterVerificationCodeScreen.vue'),
            meta: {
                guest: true
            }
        },
        {
            path: "/forgotpassword",
            name: "ForgotPassword",
            component: () => import('@/screens/auth/ForgotPasswordScreen.vue'),
            meta: {
                guest: true
            }
        },
        {
            path: "/forgotpasswordverificationcode",
            name: "ForgotPasswordVerificationCode",
            component: () => import('@/screens/auth/ForgotPasswordVerificationCodeScreen.vue'),
            meta: {
                guest: true
            }
        },
        {
            path: "/resetpassword",
            name: "ResetPassword",
            component: () => import('@/screens/auth/ResetPasswordScreen.vue'),
            meta: {
                guest: true
            }
        },
        {
            path: "/tracker",
            name: "IndexTracker",
            component: () => import('@/screens/global_tracker/IndexTracker.vue'),
            meta: {
                auth: false 
            }
        },
        {
            path: "/tracker/dashboard",
            redirect: '/tracker/dashboard',
            component: () => import('@/screens/global_tracker/ManageLayoutPage.vue'),
            meta: {
                auth: false
            },
            children:[
                {
                    path: "/tracker/dashboard", name: "TrackerDashboard",
                    component: () => import('@/screens/global_tracker/TrackerDashboard.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/mapview", name: "MapView",
                    component: () => import('@/screens/global_tracker/maps/MapView.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/devices", name: "DeviceList",
                    component: () => import('@/screens/global_tracker/devices/DeviceList.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/devices/:deviceId", name: "DevicesDetail",
                    component: () => import('@/screens/global_tracker/devices/DeviceDetail.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/users", name: "UserList",
                    component: () => import('@/screens/global_tracker/users/UserList.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/users/:userId", name: "UserDetail",
                    component: () => import('@/screens/global_tracker/users/UserDetail.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/geofences", name: "GeofenceList",
                    component: () => import('@/screens/global_tracker/geofences/GeofenceList.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/geofences/:geofenceId", name: "GeofenceDetail",
                    component: () => import('@/screens/global_tracker/geofences/GeofenceDetail.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/groups", name: "GroupList",
                    component: () => import('@/screens/global_tracker/groups/GroupList.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/groups/:groupId", name: "GroupDetail",
                    component: () => import('@/screens/global_tracker/groups/GroupDetail.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/notifications", name: "NotificationList",
                    component: () => import('@/screens/global_tracker/notifications/NotificationList.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/notifications/:notificationId", name: "NotificationDetail",
                    component: () => import('@/screens/global_tracker/notifications/NotificationDetail.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/positions", name: "PositionList",
                    component: () => import('@/screens/global_tracker/positions/PositionList.vue'),
                    meta: {
                        auth: false
                    },
                },
                {
                    path: "/tracker/positions/:positionId", name: "PositionsDetail",
                    component: () => import('@/screens/global_tracker/positions/PositionDetail.vue'),
                    meta: {
                        auth: false
                    },
                },
            ]
        },

    ],
    scrollBehavior (to, from, savedPosition) {
        // return desired position
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth)) {
        if (cookie.get('token') == null || cookie.get('token') == '') {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else if(to.matched.some(record => record.meta.guest)) {
        next();
    }else {
        next()
    }
})

export default router
