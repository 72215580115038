<template>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-card-title class="headline">
                {{ title }}
            </v-card-title>
            <v-card-text v-show="!!message" v-html="message"></v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!options.noconfirm"
                    color="primary"
                    outlined
                    @click.native="cancel"
                >
                    {{ cancelLabel }}
                </v-btn>
                <v-btn color="red darken-1" outlined @click.native="agree">
                    {{ okLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDialog",
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            cancelLabel: "Cancel",
            okLabel: "Ok",
            options: {
                color: "grey lighten-3",
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
        };
    },

    methods: {
        open(title, message, options, cancelLabel, okLabel) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            if (cancelLabel) this.cancelLabel = cancelLabel;
            if (okLabel) this.okLabel = okLabel;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            //this.reject(true);
            this.dialog = false;
        },
        close() {
            this.dialog = false;
        },
    },
};
</script>

<style>
</style>